@import "../../constants/variables.scss";

.af-footer {
  background-color: #e18530;
  color: white;
  p {
    line-height: 14px;
    font-size: 14px;
  }

  .af-aPolityFooter {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .af-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 3.5rem;
    font-size: 12px;

    .af-rowFooter {
      margin: 0;
      width: 100%;
    }

    p {
      padding-top: 5px;
      width: 100% !important;
      line-height: 10px;
      font-size: 10px;
    }
  }
}
