@import "./../../constants/variables.scss";

.af-completeSignupSectionContainer {
  .containerCheck {
    padding: 3em;
  }
  h5 {
    background-color: $darkBlue;
    color: $navbarBg;
    padding: 0.2rem 0;
    margin-bottom: 0;
    font-size: larger;
  }
  .row {
    padding: 1.5em;
    justify-content: center;
  }
  .sectionCheckbox {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    align-items: center;
    font-size: larger;
    .custom-control-label {
      color: $darkGreyText;
      &::before {
        background-color: $navbarText;
        transform: scale(2);
      }
    }
    label {
      padding-left: 30px !important;
    }
    &.hidden-label {
      .custom-control-label {
        color: transparent;
      }
    }
  }
  .af-others {
    color: $darkBlue;
  }
}
