@import "../../constants/variables.scss";

.af-logoNav {
    height: 60px;
}

.af-backgroundNav {
    background-color: $navbarBg;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: $yellow !important;
    color: white !important;
    border-radius: 30px;
}

.popover-profile-body {
    border-radius: 15px;
    border-color: $noneColor;
    width: 200px;
    background-color: $blueL;
    a {
        font-size: smaller;
        color: $white !important;
        padding: 0.5em 0em;
        text-decoration: underline;
    }
}
.popover-header {
    &::after {
        content: none !important;
    }
    &::before {
        content: none !important;
    }
}
.popover-profile-title {
    background: transparent;
    color: $white !important;
    text-align: center;
    font-size: small;
    border-bottom-color: $yellow;
    font-weight: 500;
    text-transform: capitalize;
}

.arrow:after {
    border-bottom-color: $lightBlue !important;
}
.arrow::before {
    border-bottom-color: $lightBlue !important;
}

.button-logout {
    width: 100%;
    font-size: small;
    background-color: $noneColor;
    border-radius: 0px;
    border-color: $yellow;
    &:hover {
        border-color: $noneColor !important;
        background-color: $white !important;
        color: $lightBlue !important;
    }
}

.button-poopover-desabil {
    background-color: $noneColor !important;
    color: $darkGreyText !important;
    border-radius: 0;
    border-color: $noneColor;
    border-radius: 30px;
    &:hover {
        background-color: $yellow !important;
        color: white !important;
        border-color: $noneColor;
        border-radius: 30px;
    }
}

.button-poopover {
    background-color: $yellow !important;
    color: white !important;
    border-color: $noneColor;
    border-radius: 30px;
    &:hover {
        background-color: $yellow !important;
        color: white !important;
        border-color: $noneColor;
        border-radius: 30px;
    }
}
