@import "./../../constants/variables.scss";

.af-registerContainer {
  &.af-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .container-fluid {
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }

  .af-inputIcon {
    position: absolute;
    // left: 3.5rem;
    left: 1.3rem;
    width: 2rem;
    top: 0.55rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    img {
      max-height: 100%;
    }
  }
  .af-inputs {
    margin: 5px 0 !important;
    font-weight: 500 !important;
    background-color: lightgray;
    // width: 360px;
    height: 40px;
    border-radius: 16px;
    padding-left: 2.5rem;
    &.af-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("./../../img/down_arrow_selects_icon.png");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: center;
      background-size: 10%;
    }
  }

  h2 {
    color: $salmon;
    font-weight: bold;
    text-align: center;
    margin: 3rem 0 0;
    &::before,
    &::after {
      display: inline-block;
      content: "";
      border-top: 2px solid $salmon;
      width: 14vw;
      margin: 0 1rem;
      transform: translateY(-0.5rem);
    }
  }

  .af-subtitle {
    font-weight: 500;
    text-align: center;
    padding: 0 25%;
    margin: 0.5rem 0 2.5rem;
  }

  .af-btnContinue {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: transparent;
      border: none;
      color: $green;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin: 1rem;
      color: $greenLight;

      h5 {
        margin-bottom: 0;
        margin-right: 10px;
      }
      &:hover,
      &:visited,
      &:active,
      &:focus {
        background-color: transparent !important;
        border: none !important;
        color: darken($color: $greenLight, $amount: 0.5);
        box-shadow: none !important;
      }
    }
  }
  .af-gradesBox {
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    background-color: lightgrey;
    padding: 0.375rem 2.5rem 1.5rem;
    p {
      text-align: start;
      color: $darkGreyText;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }
    .af-gradesInputs {
      display: flex;
      flex-wrap: wrap;
    }
    .sectionCheckbox {
      display: flex;
      margin: 0;
      align-items: center;
      .custom-control-label {
        color: $darkGreyText;
        font-size: 0.9rem;
        &::before {
          background-color: $navbarText;
        }
      }
    }
  }
}
