@import "../../constants/variables.scss";

.af-pageRecoverPass{
    min-height: 85vh;
    height: 100%;
}
.af-inputRecoverPass{
    .af-inputIcon {
        position: absolute;
        // left: 3.5rem;
        left: 1.3rem;
        width: 2rem;
        top: .55rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        img {
          max-height: 100%;
        }
    }

    .af-inputs{
        margin: 5px 0 !important;
        font-weight: 500 !important;
        background-color: lightgray;
        // width: 360px;
        height: 40px;
        border-radius: 0;
        padding-left: 2.5rem;
        &.af-select {
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url('./../../img/down_arrow_selects_icon.png');
          background-repeat: no-repeat;
          background-position-x: 100%;
          background-position-y: center;
          background-size: 10%;
        }
    }
}