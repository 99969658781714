@import "./../../constants/variables.scss";

.af-completeSignupPageContainer {
  .container-fluid {
    padding: 0;
    overflow-x: hidden;
  }
  h2 {
    color: $salmon;
    font-weight: bold;
    text-align: center;
    margin: 3rem 0;
    &::before,
    &::after {
      display: inline-block;
      content: "";
      border-top: 2px solid $salmon;
      width: 14vw;
      margin: 0 1rem;
      transform: translateY(-0.5rem);
    }
  }
  .af-location {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    h5 {
      color: $darkBlue;
      font-weight: bold;
    }
    select {
      background: lightgray url("./../../img/down-arrow-icon.png") no-repeat right 1rem center/19px 24px;
      color: $darkGreyText;
      font-weight: bold;
      &:hover,
      &:visited,
      &:active,
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

  .af-onlyPrincipal {
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-control-label {
      color: $darkGreyText;
      &::before {
        background-color: $navbarText;
      }
    }
    .af-princText {
      color: $darkBlue;
    }
    .af-options {
      display: flex;
      width: 100%;
      margin-top: 1rem;
    }
  }

  &.af-false {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    .container-fluid,
    form {
      height: auto;
      overflow: inherit;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .inputSignup {
    display: flex;
    justify-content: center;
  }

  &.af-principal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    height: auto;
    .container-fluid,
    form {
      min-height: 100%;
      overflow: inherit;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .af-btnContinue {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: transparent;
      border: none;
      color: $green;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin: 1rem;
      color: $greenLight !important;

      h5 {
        margin-bottom: 0;
        margin-right: 10px;
      }
      &:hover,
      &:visited,
      &:active,
      &:focus {
        background-color: transparent !important;
        border: none !important;
        color: $greenLight !important;
        box-shadow: none !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .af-completeSignupPageContainer {
    h2 {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
