@import "../../constants/variables.scss";

.af-tittleIntro {
  color: #0ba099;
}

.af-backImageDown {
  width: 100%;
  height: auto;
}

.principal-page-introduction {
  height: 100%;
}

.af-divIntro {
  // min-height: 100vh;
}
.af-buttonList {
  display: flex;
  flex-wrap: wrap;

  .btn-primary.disabled,
  .btn-primary:disabled {
    border: none;
    background-color: rgba($aquamarineBlue, $alpha: 0.8);
    cursor: default;
  }
}
.af-selectGrade {
  background-color: $blueL;
  font-family: GhotamR;
  border-radius: 15px;
  font-weight: bold;
  color: white;
  z-index: 10;
}
.af-buttonSubject {
  font-family: GhotamR;
  font-weight: bold;
  font-size: 20px;
  background-color: $aquamarineBlue;
  border: none;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
.af-lineOrangeIntro {
  border: 4px solid $yellow;
  border-radius: 30px;
}

.af-buttonHomeIntroduction {
  background-color: $yellow;
  border-radius: 0px 30px 30px 0px;
  width: 50px;
  height: 50px;
  font-size: 40px;
  color: white;
  z-index: 10;
}

.relative-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.af-fox-intro {
  width: 10vw; // Ajusta a ancho relativo
  max-width: 100px;
  height: auto;
  position: relative; // Cambia de absolute a relative
  z-index: -10;

  @media (max-width: 768px) {
    width: 8vw;
    max-width: 80px;
  }
}

.af-backImageForm {
  transform: scale(0.5) translateX(12em) !important;
  position: absolute;
  bottom: -10vh;
}

@media (min-width: 1440px) {
  .af-backImageForm {
    transform: scale(0.5) translateX(18em) !important;
    bottom: 15vh;
  }
}
@media (min-width: 2560px) {
  .af-backImageForm {
    transform: scale(0.2) translateX(55em) !important;
    bottom: 50vh;
  }
}
