@import "../../constants/variables.scss";

.af-formCallcenter {
  .form-row {
    margin-left: 0;
    margin-right: 0;
    & > .col {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .af-mayusName{
    text-transform: uppercase;
  }

  .af-inputs {
    //margin: 5px 0 !important;
    font-weight: 500 !important;
    background-color: lightgray;
    // width: 360px;
    min-height: 2.5em;
    border-radius: 10px;
    border: none;
    color: #495057 !important;
    .af-textMayus {
      text-transform: uppercase !important;
    }

    .af-inputCode {
      font-weight: 500 !important;
      background-color: lightgray;
      height: 40px;
      border-radius: 15px;
      text-transform: capitalize;
    }

    &.af-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("./../../img/down_arrow_selects_icon.png");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: center;
      background-size: 12%;
    }

  }
  .af-buttonCcenter {
    background-color: $yellow !important;
    border: none;
    border-radius: 30px;
    font-weight: bold;
  }
}

