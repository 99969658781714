@import "../../constants/variables.scss";

.af-background {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0;
}

.af-header {
  background-color: white;
}

.af-body {
  background-image: url("../../img/backgroundAlternaTIC.png");
  background-size: 100% 140%;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 100%;
  background-attachment: local;
  position: relative;

  &::before {
    content: " ";
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    opacity: 0.64;
  }
}

.af-benefity {
  background-image: url("../../img/backgroundBeneficios.png");
  background-size: 100% 130%;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 100%;
  background-attachment: local;
  position: relative;

  &::before {
    content: " ";
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    opacity: 0.64;
  }
}

// Images
.af-backImage {
  position: absolute;
  width: 15%;
  height: auto;
}

.af-backImageUpper {
  position: absolute;
  width: 100%;
  height: 7%;
}

.af-icon {
  font-size: 50px;
}

.af-text {
  font-size: 10px;
}
.af-resource {
  background-color: $navbarBg;
  height: 100vh;
}
.af-tittleHome {
  color: $darkBlue;
}

.af-backContact {
  background-image: url("../../img/backgroundContact.png");
  background-size: 100% 160%;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 100%;
  background-attachment: local;
  position: relative;
  padding-bottom: 200vh;
  &::before {
    content: " ";
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    opacity: 0.64;
  }
}

.af-buttonres {
  background-color: $yellow;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  border: none;
}

.af-buttonRanking {
  background-color: $aquamarineBlue;
  border: none;
}

.af-line {
  border-bottom: 2px solid $darkBlue;
}

.af-divNew {
  position: absolute;
  background-color: rgba($darkBlue, 0.7);
  color: white;
  font-size: 10px;
}

.af-titleOrange {
  color: $salmon;
}

.af-titleRedOrange {
  color: #ae4b00;
}

.af-lineOrange {
  border-bottom: 2px solid $salmon;
}

.af-lineOrange2 {
  border-bottom: 3px solid #ae4b00;
}

.af-form {
  border: 2px solid $darkBlue;
  border-radius: 30px;
}
.af-form2 {
  border: 2px solid $darkBlue;
  border-radius: 20px;
}

.carousel-indicators li {
  background-color: $aquamarineBlue;
}

.af-divContentNew {
  position: relative;
}

.af-divContentNew {
  position: relative;
}

[class^="carousel-control"] span {
  position: relative;
  &::before {
    content: "";
    background: #372a57;
    z-index: -1;
    width: 3em;
    height: 3em;
    position: absolute;
    top: -67%;
    right: -0.85em;
    border-radius: 50%;
  }
}

.vertical-carousel .carousel-item {
  display: none;
}

.vertical-carousel .carousel-item.active {
  display: block;
}

.vertical-carousel img {
  max-height: 60vh;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

@media (min-width: 1440px) {
  .af-textContact1 {
    margin-bottom: 100px;
  }
}
