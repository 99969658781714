.af-imageUpper {
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: -10;
}

.principal-page-report {
}
