@import "../../constants/variables.scss";

.af-buttonLear{
    border: 2px solid $aquamarineBlue;
    border-radius: 10px;
    background-color: white;
    color:$aquamarineBlue;
    .af-imgButtonAula{
        width:10%;
    }
    &:hover{
        background-color: $green !important;
        border: none !important;
        color: white !important;
        box-shadow: none !important;

        .cls-1{
            fill:white;
        }
      }
}
.af-text{
    font-size: 14px;
    text-align: justify;
}

.af-iconReport{
    width: 35px;
}
.af-tittleProgress{
    font-family: GhotamR;
}