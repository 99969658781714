@import "../../constants/variables.scss";

.af-buttonBackVideo{
    width: 30px;
    height: 30px;
    color:white;
border-radius: 50%;
background-color: $yellow;
border:none
}

.af-textVideos{
    color:$yellow;
    margin-top: 12px;
    font-weight: bold;
}

.af-titleZoomVideo{
    text-transform: capitalize;
}