@import "./../../constants/variables.scss";

.af-choseCoordinaOp {
  .af-buttonMoni {
    background-color: $yellow;
  }
}

.af-backImageDown {
  width: 100%;
  height: auto;
}

.af-tittleChooseConten {
  color: #0ba099;
}
