$colorTitle: #f26945;
.title-seccion-page {
    overflow: hidden;
    text-align: center;
    color: $colorTitle;
}

.title-seccion-page:before,
.title-seccion-page:after {
    background-color: $colorTitle;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.title-seccion-page:before {
    right: 0.5em;
    margin-left: -50%;
}

.title-seccion-page:after {
    left: 0.5em;
    margin-right: -50%;
}
