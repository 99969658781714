@import "../../constants/variables.scss";

.af-Progreesbar-videos {
    .CircularProgressbar .CircularProgressbar-path {
        stroke: $darkBlue !important;
        stroke-linecap: butt !important;
    }
}
.af-Progreesbar-actividades {
    .CircularProgressbar {
        .CircularProgressbar-path {
            stroke: $aquamarineBlue !important;
            stroke-linecap: butt !important;
        }
    }
}
.af-Progreesbar-recursos {
    .CircularProgressbar .CircularProgressbar-path {
        stroke: $lightBlue !important;
        stroke-linecap: butt !important;
    }
}
.af-Progreesbar-contenidos {
    .CircularProgressbar .CircularProgressbar-path {
        stroke: $green !important;
        stroke-linecap: butt !important;
    }
}

[class^="af-Progreesbar"] {
    max-width: 6em;
}
