@import "../../constants/variables.scss";

.af-tittleLesson{
    color: $salmon;
    font-weight: bold;

}
.af-textLesson{
    font-size: 10px;
}

.page-item.active .page-link{
    background-color: $aquamarineBlue!important;
    border:none;
}

.af-buttonAula{
    border: 2px solid $darkBlue;
    border-radius: 20px;
    background-color: white;
    color:$darkBlue;
    .af-imgButtonAula{
        width:10%;
    }
    .cls-1{
        fill:$darkBlue;
    }
    &:hover{
        background-color: $yellowHover !important;
        border: none !important;
        color: white !important;
        box-shadow: none !important;
        .cls-1{
            fill:white;
        }
      }
}