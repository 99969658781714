@import "../../constants/variables.scss";

.report-container {
  position: relative;
  overflow: hidden; /* Asegura que los elementos no se desborden */
}

.af-backImageUpperTeacher {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.af-backImageDown {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
}

.home-button-container {
  position: absolute;
  top: 50px;
  z-index: 10;
}

.content-container {
  position: relative;
  z-index: 2;
  padding-top: 50px; /* Espacio para que el botón de inicio no se superponga */
}

.decorative-images {
  position: relative; /* Cambio a relative para manejar mejor el posicionamiento */

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none; /* Evita la interferencia en la interacción con otros elementos */
  z-index: 1;
}

.lapiz-report {
  width: 15%;
  max-width: 200px;
  position: absolute;
  right: 2%; /* Ajuste para desplazar el cohete más a la derecha */
  bottom: 40%; /* Ajuste para colocarlo en la parte baja */
  z-index: 1;
}

.report-estrellas-izquierda {
  width: 50%;
  max-width: 700px;
  position: absolute;
  left: 3%; /* Ajuste para desplazar la estrella hacia la izquierda */
  bottom: 50%; /* Ajuste para que esté más abajo */
  z-index: -1;
}

.report-estrellas-derecha {
  width: 30%;
  max-width: 600px;
  position: absolute;
  right: 2%;
  bottom: 50%;
  z-index: -1;
}

.form-report-Infor {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: $aquamarineBlue !important;
    opacity: 0.5;
  }
}
