@import "../../constants/variables.scss";


.af-backArrow{
border-radius: 50%;
background-color: $yellow;
color:white;
font-size: 20px;
}

.af-buttonActivity{
background:transparent;
border:none;
}

.af-buttonActivity:hover{
    background-color: transparent !important;
    border:1px solid $yellow !important;
}

.af-textZoom {
    text-align: justify;
}