.new-img {
  width: 100%;
  height: 26vw !important;
  min-height: 15em;
  height: fit-content;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.head-Card {
  background-color: #1c3182;
  color: white;
  border-radius: 0px !important;
}

.card-news:hover {
  cursor: pointer;
}
