/*@font-face {
   font-family: "Roboto";
  src: url("./Fonts/Roboto/Roboto-Black.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-Italic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-Light.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-LightItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-LightItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-Medium.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-Regular.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-Thin.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype"),
    url("./Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
} */

@mixin font-face($name, $path, $weight: null, $style: null) {
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $path;
  }
}

@include font-face("Roboto", "./Fonts/Roboto/Roboto-Black.ttf", "900", "black");
@include font-face("Roboto", "./Fonts/Roboto/Roboto-Bold.ttf", "700", "bold");
@include font-face("Roboto", "./Fonts/Roboto/Roboto-Light.ttf", "300", "ligth");
@include font-face(
  "Roboto",
  "./Fonts/Roboto/Roboto-Medium.ttf",
  "500",
  "medium"
);
@include font-face(
  "Roboto",
  "./Fonts/Roboto/Roboto-Regular.ttf",
  "400",
  "normal"
);
@include font-face("Roboto", "./Fonts/Roboto/Roboto-Thin.ttf", "100", "thin");

@include font-face(
  "GhotamR",
  url("./Fonts/Ghotam/GothamRounded-Bold.otf"),
  "400",
  "bold"
);
@include font-face(
  "Ghotam",
  url("./Fonts/Ghotam/GothamRounded-Book.otf"),
  "100",
  "normal"
);

body {
  font-family: "Roboto", sans-serif !important;
}

body {
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[type="checkbox"] {
  opacity: 0;
  + label {
    &::before,
    &::after {
      position: absolute;
      content: "";

      /*Needed for the line-height to take effect*/
      display: inline-block;
    }
    &::before {
      height: 16px;
      width: 16px;

      //border: 1px solid;
      background-color: lightgray;
      left: 0px;

      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
      top: 3px;
    }
    &::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;

      transform: rotate(-45deg);

      left: 4px;
      top: 7px;
      color: #495057 !important;
      content: none;
    }
  }
  &:checked {
    + label {
      &::after {
        content: "";
      }
    }
  }
}

//print
.logo-print {
  display: none;
}
@media print {
  .logo-print {
    max-height: 5em;
    display: inline;
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 10;
  }
  .container {
    margin-top: 5em;
  }
  .af-buttonHomeIntroduction {
    opacity: 0;
  }
}

.list-altic {
  display: flex;
  flex-wrap: wrap;
}