@import "../../constants/variables.scss";

.af-chooseAButton{
    width: 160px;
    height: 160px;
    background-color: $darkBlue;
    border:none;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
}