@import "../../constants/variables.scss";

.report-container {
  position: relative;
  overflow: hidden; /* Asegura que los elementos no se desborden */
}

.af-backImageUpperReport {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.af-backImageDown {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
}

.home-button-container {
  position: absolute;
  top: 50px;
  z-index: 10;
}

.content-container {
  position: relative;
  z-index: 2;
  padding-top: 50px; /* Espacio para que el botón de inicio no se superponga */
}

.decorative-images {
  position: relative; /* Cambio a relative para manejar mejor el posicionamiento */

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none; /* Evita la interferencia en la interacción con otros elementos */
  z-index: 1;
}

.lapiz-report {
  width: 15%;
  max-width: 200px;
  position: absolute;
  right: 2%; /* Ajuste para desplazar el cohete más a la derecha */
  bottom: 40%; /* Ajuste para colocarlo en la parte baja */
  z-index: 1;
}

.report-estrellas-izquierda {
  width: 50%;
  max-width: 700px;
  position: absolute;
  left: 3%; /* Ajuste para desplazar la estrella hacia la izquierda */
  bottom: 50%; /* Ajuste para que esté más abajo */
  z-index: -1;
}

.report-estrellas-derecha {
  width: 30%;
  max-width: 600px;
  position: absolute;
  right: 2%;
  bottom: 50%;
  z-index: -1;
}

/* botón de home */
.button-home-link > a {
  width: 3em;
  height: 3em;
  padding: 5px !important;
}
.button-home-link > a > svg {
  width: 2.5em;
  height: 2.5em;
  fill: white;
}

.button-home-link {
  width: 3em;
  height: 3em;
  background-color: #1c3182;
  position: fixed;
  margin-top: 2em;
  left: 0;
  @media (max-width: 620px) {
    z-index: 1000;
    left: unset;
    right: 0;
    bottom: 0;
  }
}

/* Resto de las clases */
.title-report {
  color: #0ba099;
  font-family: GhotamR;
}

.separator {
  height: 0.7em;
  width: 17em;
  background-color: $yellow;
  border-radius: 5em;
}

.af-tittleReport {
  color: $darkBlue;
  font-weight: bold;
}

.af-reportselect {
  font-weight: 700;
  background-color: $blueSelect;
  color: white !important;
  font-family: GhotamR;
  border: none;
  border-radius: 15px;
  color: $darkBlue;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  //text-transform: capitalize;
  background-image: linear-gradient(45deg, transparent 50%, #15446a 50%),
    linear-gradient(135deg, #15446a 50%, transparent 50%);
  background-position: calc(100% - 14px) calc(1em + -2px),
    calc(100% - 4px) calc(1em + -2px), 100% 0;
  background-size: 10px 10px, 10px 10px, 3.5em 3.5em;
  background-repeat: no-repeat;
  option {
    text-transform: uppercase !important;
  }
}

.af-reportselect:focus {
  font-weight: 700;
  background-color: $bgGreySelects;
  color: $darkBlue;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #15446a 50%),
    linear-gradient(135deg, #15446a 50%, transparent 50%);
  background-position: calc(100% - 14px) calc(1em + -2px),
    calc(100% - 4px) calc(1em + -2px), 100% 0;
  background-size: 10px 10px, 10px 10px, 3.5em 3.5em;
  background-repeat: no-repeat;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: $darkBlue !important;
  stroke-linecap: butt !important;
}

.af-Progreesbar-recursos {
  .CircularProgressbar .CircularProgressbar-path {
    stroke: $lightBlue !important;
    stroke-linecap: butt !important;
  }
}

.af-Progreesbar-contenidos {
  .CircularProgressbar .CircularProgressbar-path {
    stroke: $green !important;
    stroke-linecap: butt !important;
  }
}

.af-buttonGenerateReport {
  border: 2px solid $darkBlue;
  background-color: white;
  color: $darkBlue;
  .af-imgButtonAula {
    width: 10%;
  }
  &:hover {
    background-color: $green !important;
    border: none !important;
    color: white !important;
    box-shadow: none !important;
  }
}

// .CircularProgressbar .CircularProgressbar-path {
//     stroke:$darkBlue !important;
//     stroke-linecap:butt!important;
// }

.CircularProgressbar .CircularProgressbar-text {
  fill: $black !important;
  font-weight: bold;
}

.selected-type {
  height: 60px;
  font-weight: 700;
  background-color: $bgGreySelects;
  color: $darkBlue;
}

.subtitle-report {
  color: $salmon;
  font-weight: 700;
}

.subtitle-report > h3 {
  color: $salmon;
  font-weight: 700;
}

.icon-report {
  width: 3em;
  height: auto;
  margin-right: 0.5em;
}

.icon-generate-report {
  max-width: 3em;
  height: auto;
  margin-right: 0.5em;
}

/* Boton de enviar reporte */
.Butoon-generate-report {
  background-color: $aquamarineBlue;
  border-color: $aquamarineBlue;
  font-weight: 700;
}

.Butoon-generate-report:hover {
  background-color: $aquamarineBlueDark;
  border-color: $aquamarineBlueDark;
}

.Butoon-generate-report:focus {
  background-color: $aquamarineBlueDark;
  border-color: $aquamarineBlueDark;
}

.Butoon-generate-report:active {
  background-color: $aquamarineBlueDark !important;
  border-color: $aquamarineBlueDark !important;
}

/* Boton de generar Reporte */
.generate-report:hover > .generate-report-text {
  color: $greenLight;
  font-weight: 700;
  cursor: pointer;
}

.generate-report-text {
  color: $green;
  font-weight: 700;
  font-size: 30px;
}

.generate-report-text:hover {
  color: $greenLight;
  font-weight: 700;
  cursor: pointer;
}

.generate-report-button {
  background-color: transparent;
  border-color: transparent;
  color: $green;
  font-weight: 700;
  font-size: 40px;
}

.generate-report-button:hover {
  background-color: transparent;
  border-color: transparent;
  color: $greenLight;
  font-weight: 700;
}

.generate-report-button:focus {
  background-color: transparent;
  border-color: transparent;
  color: $greenLight;
  font-weight: 700;
}

.generate-report-button:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $greenLight !important;
  font-weight: 700 !important;
}

.generate-report-button:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $greenLight !important;
  font-weight: 700 !important;
}

// clase reporte de estudiantes
.af-iconReport-student {
  width: 2.5em;
}

.form-report-Infor {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: $aquamarineBlue !important;
    opacity: 0.5;
  }
}
