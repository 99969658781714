@import "../../constants/variables.scss";

.af-backImage {
    position: absolute;
    width: 15%;
    height: auto;
    right: 0;
    transform: scaleX(-1);
}
.af-nav {
    top: 10em;
    a {
        text-transform: capitalize;
        color: $darkBlue;
    }
}
.af-nav-parent {
    text-align: left;
    transition: 0.25s;
    .af-nav-children {
        opacity: 0;
        position: absolute;
        right: 100%;
    }
    &:hover {
        .af-nav-children {
            opacity: 1;
            position: initial;
        }
    }
}
