.list-altic {
  .list-btn-custom-class {
      border-color: #0f958e !important;
      background-color: #0f958e !important;
      opacity: 0.5;
      &.active {
          opacity: 1;
      }
      .btn-group button:last-child {
          border-radius: 0 10em 10em 0 !important;
          margin-left: -1px;
      }

      .btn-group button:only-child {
          border-radius: 10em;
          margin-left: 0;
      }
  }
}
.table-altic {
  th {
      text-align: center;
      vertical-align: middle;
      color: #735ab4;
  }
  td,
  th {
      border-color: #0f958e !important;
  }
  select {
      margin: 5px 0 !important;
      font-weight: 500 !important;
      background-color: lightgray !important;
      color: #495057 !important;
      // width: 360px;
      height: 40px;
      border-radius: 16px;
      padding-left: 2.5rem;
  }
}
