@import "../../constants/variables.scss";

// .af-main{
// height: 100vh;
// }

.af-buttonHome {
    background-color: $yellow;
    border-radius: 0px 30px 30px 0px;
    width: 50px;
    height: 50px;
    font-size: 40px;
    color: white;
}
.af-tittle {
    background-color: $aquamarineBlue;
    border-radius: 0px 30px 30px 0px;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
    p {
        font-family: GhotamR !important;
    }
}

.af-list {
    background-color: $bgGrey;
    color: $aquamarineBlue;
    border: none;
    width: 100%;
    font-size: 10px;
    font-weight: bold;

    &.af-icon {
        background-color: transparent !important;
        width: 35px;
    }

    &:hover,
    &:visited,
    &:active,
    &:focus {
        background-color: $yellowHover !important;
        border: none !important;
        color: white !important;
        box-shadow: none !important;

        .cls-1 {
            fill: white;
        }
    }
    p {
        font-size: 18px;
    }
}
.af-list:hover {
    background-color: $green;
}
.af-text {
    font-size: 10px;
    text-align: justify;
}

.af-divDetail {
    height: 100vh;
}

.af-iconButtonlist {
    width: 30px;
}

.af-tittleProgress {
    color: $aquamarineBlue;
}

//------------------------ float button --------------------------

.float {
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 40px;
    right: 20px;
    background-color: $bgGrey;
    color: $aquamarineBlue;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    font-size: 25px;
}

.af-ulButtonfloat {
    position: fixed;
    right: 20px;
    padding-bottom: 20px;
    bottom: 50px;
    z-index: 100;

    li {
        list-style: none;
        margin-bottom: 10px;
    }

    button {
        background-color: $bgGrey;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
        width: 50px;
        padding: 10px;
        height: 50px;
        display: block;
        border: none;
        &:hover,
        &:visited,
        &:active,
        &:focus {
            background-color: $green !important;
            border: none !important;
            color: white !important;
            box-shadow: none !important;
        }
    }
}

ul:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.my-float {
    margin-top: 18px;
    font-size: 20px;

    &.af-icon {
        background-color: transparent !important;
        .cls-1 {
            width: 10px;
        }
    }

    &:hover,
    &:visited,
    &:active,
    &:focus {
        .cls-1 {
            fill: white;
        }
    }
}
.af-div {
    width: 40px;
    svg {
        max-height: 4em;
    }
}

.af-div2 {
    width: 30px;
}

.af-iconHomenFloat {
    color: $aquamarineBlue;
}
.float#menu-share + ul {
    visibility: hidden;
}

.float#menu-share:hover + ul {
    visibility: visible;
    animation: scale-in 0.5s;
}

@keyframes bot-to-top {
    0% {
        bottom: -40px;
    }
    50% {
        bottom: 40px;
    }
}

@keyframes scale-in {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

//--------------------------------------
