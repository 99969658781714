@import "../../constants/variables.scss";

.af-backImageUpper {
  position: absolute;
  width: 100%;
  height: 7%;
}

.af-backImageForm {
  position: absolute;
  right: 1%; // Ajusta la posición a la derecha del cohete
  width: 500px; // Tamaño máximo para evitar que desborde
  height: auto;
  margin-right: 20px; // Espacio desde el borde derecho de la página
  margin-bottom: -150px; // Espacio desde el borde inferior
  z-index: 0; // Asegura que no se sobreponga a otros elementos
}

.af-styleInput {
  background-color: $blueL;
  border-radius: 30px;
  color: white;
}

.af-buttonLogin {
  background-color: $yellow;
  border-radius: 30px;
  font-weight: bold;
  border: none;
  font-size: 20px;
}

.af-linkRegister {
  color: $darkBlue;
}

.af-astrosLogin {
  max-width: 280px; // Ajusta según el diseño
  height: auto;
  margin-top: -150px; // Ajusta la posición vertical para alinear con el formulario
  margin-left: -20px; // Ajusta el espacio desde el formulario
  position: relative; // Asegura que se mantenga dentro de su contenedor
}

.af-astrosLogin2 {
  max-width: 400px; // Ajusta el ancho de la figura
  height: auto;
  margin-top: -500px; // Ajusta este valor para la alineación vertical
  position: relative; // Asegura que la posición sea relativa a su contenedor
}

.af-boardLogin {
  max-width: 300px; // Ajusta según el diseño
  height: auto;
  margin-top: -35px;
}

.custom-input {
  width: 280px; // Ajusta el ancho según el diseño
  height: 30px; // Ajusta la altura según el diseño
  border-radius: 20px; // Redondeado de bordes similar al diseño
  border: 2px solid #007bff; // Color del borde para que resalte
  background-color: #2665b4; // Fondo blanco para inputs
  padding: 10px 20px; // Espaciado interior
  font-size: 20px; // Ajusta el tamaño de la fuente
  color: #bababa; // Color del texto
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Sombra ligera para dar profundidad
}

.custom-input::placeholder {
  color: #949faa; // Color del texto placeholder
  opacity: 1; // Asegura que sea completamente visible
  font-weight: bold; // Texto en negrita para el placeholder
}

.form-container {
  display: flex;
  flex-direction: column; // Alinea los elementos en columna
  justify-content: center; // Centra verticalmente
  align-items: center; // Centra horizontalmente
  width: 100%; // Puede ser un ancho específico, como 300px
  max-width: 400px; // Limita el ancho máximo para que no se expanda demasiado
  margin: 0 auto; // Asegura que el contenedor esté centrado en la página
  padding: 10px; // Espaciado alrededor
}

.additional-content {
  margin-top: 20px; // Ajusta este valor según sea necesario
  text-align: center; // Centra los elementos
}

.af-error {
  margin-top: -35px;
  margin-bottom: -5px;
}
