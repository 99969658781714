@import "../../constants/variables.scss";

.af-chooseButton {
  width: 150px;
  height: 150px;
  background-color: $darkBlue;
  border: none;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.af-backImageDown {
  width: 100%;
  height: auto;
}

.af-tittleChooseConten {
  color: #0ba099;
}

.af-lineOrangechoose {
  border: 4px solid $yellow;
  border-radius: 30px;
}
