$navbarBg: #f6f6f6;
$navbarText: #878787; // also checkboxes bg
$darkGreyText: #575756;
$bgGrey: #ededed;
$bgGreySelects: #c5c5c5;
$salmon: #735ab4; // se cambio en el nuevo diseño de educatic
$darkBlue: #0f958e; // se cambio en el nuevo diseño de educatic
$lightBlue: #359ad4;
$aquamarineBlue: #227f88;
$aquamarineBlueDark: #065258;
$blueL: #2665b4; // se agrego por nuevo diseño de educatic
$green: #8ac92e;
$greenLight: #2665b4;
$blueSelect: #2cafbd;
$black: #1d1d1b;
$white: #ffff;
$yellow: #f6a83d;
$yellowHover: #ffce32;
$noneColor: #fa000000;

p {
  font-family: Ghotam;
}

strong {
  font-family: GhotamR !important;
}

h2 {
  font-family: Ghotam;
  font-weight: bold;
}
