@import "../../constants/variables.scss";

.af-buttonEdu {
    border: 2px solid $aquamarineBlue;
    background-color: white;
    color: $aquamarineBlue;
    .af-imgButtonAula {
        width: 10%;
    }
    &:hover {
        background-color: $yellowHover !important;
        border: none !important;
        color: white !important;
        box-shadow: none !important;

        .cls-1 {
            fill: white;
        }
    }
}
.af-thumbnail {
    border: none;
    background: none;
    transition: 0.25s;
    img {
        max-width: 100%;
    }
    &:hover {
        transform: scale(1.05);
    }
}
